import React from 'react'
import { get } from 'lodash'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Layout from '../components/layout'
import Container from '../components/container'
import Marquee from '../components/marquee'
import VideoPlayer from '../components/video-player'
import * as styles from './index.module.css'

class RootIndex extends React.Component {
  render() {
    const pageContent = get(this, 'props.data.contentfulPage')
    return (
      <Layout location={this.props.location}>
        <div className={styles.index}>
          {pageContent?.videoUrl && (
            <div className={styles.videoWrapper}>
              <VideoPlayer url={pageContent?.videoUrl} />
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          <Container isNarrow>
          {pageContent?.pageDescription?.raw &&
            renderRichText(pageContent.pageDescription)}
          </Container>
          <Marquee>Our Bodies Our Voices</Marquee>
          <Container isNarrow>
          <div className={styles.about}>
          {pageContent?.pageDescription2?.raw &&
            renderRichText(pageContent.pageDescription2)}
          {pageContent?.pageDescription3?.raw &&
            renderRichText(pageContent.pageDescription3)}
          </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PageQuery {
    contentfulPage(pageName: { eq: "About" }) {
      videoUrl
      pageDescription {
        raw
      }
      pageDescription2 {
        raw
      }
      pageDescription3 {
        raw
      }
    }
  }
`
