import React from 'react'

import * as styles from './marquee.module.css'

const Marquee = ({ children }) => {
  return (
    <div className={styles.scrollContainer}>
      <div className={styles.scrollContent}>
        { children }{' '}
        { children }{' '}
        { children }{' '}
        { children }{' '}
        { children }{' '}
        { children }{' '}
        { children }{' '}
        { children }{' '}
        { children }{' '}
      </div>
    </div>)
}

export default Marquee
